import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import Tabs from '../../../components/shared/Tabs'
import { ORDER_STATUS_ENUM, getEnums } from '../../../utilities/enum'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Icon from '../../../components/shared/Icon'
import { useOrderService } from '../../../services/apiServices'
import Table from '../../../components/shared/Table'
import TableSearch from '../../../components/shared/TableSearch'
import TableFilter from '../../../components/shared/TableFilter'
import Checkbox from '../../../components/shared/Checkbox'
import { BASE_URL_API_SLEEK_ECOM, DEFAULT_VALUE } from '../../../utilities/constant'
import NumberFormat from '../../../components/shared/NumberFormat'
import { Tag } from 'antd'
import OrderFilter from './OrderFilter'
import { downloadFile } from '../../../utilities/sharedFunction'

const STATUSCOLOR = {
  [ORDER_STATUS_ENUM.WAITFORCONFIRM]: 'blue',
  [ORDER_STATUS_ENUM.PREPARINGGOODS]: 'purple',
  [ORDER_STATUS_ENUM.WAITFORDELIVERY]: 'volcano',
  [ORDER_STATUS_ENUM.DELIVERYRECEIVED]: 'cyan',
  [ORDER_STATUS_ENUM.COMPLETE]: 'green',
  [ORDER_STATUS_ENUM.CANCEL]: 'red',
  [ORDER_STATUS_ENUM.RETURNREFUND]: 'magenta',
}

const Orders = () => {
  const intl = useIntl()
  const { getListAsync, preparingGoodsAsync, waitForDeliveryAsync, completeAsync, getListExportAsync } = useOrderService()

  const [status, setStatus] = useState(ORDER_STATUS_ENUM.WAITFORCONFIRM)

  const [filter, setFilter] = useState()
  const [onReload, setOnReload] = useState(false)
  const statuses = getEnums(ORDER_STATUS_ENUM, intl, 'Ecom.Order')

  useEffect(() => {
    setOnReload(true)
  }, [filter, status])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, status })
  }

  const handleExportExcel = async () => {
    setOnReload(false)
    const { result } = await getListExportAsync({ ...filter })
    if (result && result !== '') {
      downloadFile(BASE_URL_API_SLEEK_ECOM + result)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleChangeStatus = async (id) => {
    const statusActions = {
      [ORDER_STATUS_ENUM.WAITFORCONFIRM]: preparingGoodsAsync,
      [ORDER_STATUS_ENUM.PREPARINGGOODS]: waitForDeliveryAsync,
      [ORDER_STATUS_ENUM.WAITFORDELIVERY]: completeAsync,
      [ORDER_STATUS_ENUM.DELIVERYRECEIVED]: completeAsync,
    }

    const action = statusActions[status]

    if (action) {
      const { error } = await action(id)
      if (_.isEmpty(error)) {
        showToast(intl.formatMessage({ id: 'Notify.Success' }), TOAST_TYPES.SUCCESS)
        setOnReload(true)
      } else {
        showToast(intl.formatMessage({ id: 'Notify.Error' }), TOAST_TYPES.ERROR)
      }
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Order.Code' })}`,
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: 150,
      showSorterTooltip: false,
      render: (item) => {
        const isActionRequired = [
          ORDER_STATUS_ENUM.WAITFORCONFIRM,
          ORDER_STATUS_ENUM.PREPARINGGOODS,
          ORDER_STATUS_ENUM.WAITFORDELIVERY,
          ORDER_STATUS_ENUM.DELIVERYRECEIVED,
        ].includes(item.status)

        const statusTag = (
          <Tag color={STATUSCOLOR[item.status]} className='me-1 fs-7 cursor-pointer'>
            {intl.formatMessage({ id: `Ecom.Order.${item.statusName.toUpperCase()}` })}
          </Tag>
        )

        return (
          <div className='d-flex flex-column'>
            <div>{item.code}</div>
            <div>
              {isActionRequired ? (
                <Confirm
                  title={intl.formatMessage({ id: 'Notifications' })}
                  description={intl.formatMessage({ id: 'Confirm.Message' })}
                  onConfirm={() => handleChangeStatus(item.id)}
                  button={statusTag}
                />
              ) : (
                statusTag
              )}
            </div>
          </div>
        )
      },
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Order.Customer' })}`,
      key: 'customer',
      sorter: (a, b) => a.customer.fullName.localeCompare(b.customer.fullName),
      showSorterTooltip: false,
      width: 100,
      render: (item) => item.customer.fullName,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Order.OrderDate' })}`,
      dataIndex: 'orderDate',
      sorter: (a, b) => a.OrderDate.localeCompare(b.OrderDate),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Order.GrandTotal' })}`,
      dataIndex: 'grandTotal',
      sorter: (a, b) => a.grandTotal - b.grandTotal,
      width: 80,
      showSorterTooltip: false,
      render: (grandTotal) => <NumberFormat amount={grandTotal} suffix={DEFAULT_VALUE.VND} />,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => {
        const isActionRequired = [
          ORDER_STATUS_ENUM.WAITFORCONFIRM,
          ORDER_STATUS_ENUM.PREPARINGGOODS,
          ORDER_STATUS_ENUM.WAITFORDELIVERY,
          ORDER_STATUS_ENUM.DELIVERYRECEIVED,
        ].includes(item.status)

        return isActionRequired ? (
          <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
            <Confirm
              title={intl.formatMessage({ id: 'Notifications' })}
              description={intl.formatMessage({ id: 'Confirm.Message' })}
              onConfirm={() => handleChangeStatus(item.id)}
              button={
                <div className='btn btn-sm btn-icon btn-light-danger'>
                  <Icon iconName='CheckOutlined' />
                </div>
              }
            />
          </div>
        ) : (
          <></>
        )
      },
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'ExportExcel' })}
          icon='ExportOutlined'
          className='btn-primary'
          onClick={() => handleExportExcel()}
          tabIndex={1}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<OrderFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <Tabs
      header={statuses}
      selectedTab={status}
      onChange={setStatus}
      content={
        <div className='card mb-5'>
          <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
        </div>
      }
    />
  )
}

export default Orders
