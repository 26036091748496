import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useDashboardService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_ECOM
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.DASHBOARD)

  const orderStatisticsAsync = async (payload) => {
    return await api.postAsync('order-statistics', { payload })
  }

  const salesRevenueAsync = async (payload) => {
    return await api.postAsync('sales-revenue', { payload })
  }

  const newCustomersAsync = async (payload) => {
    return await api.postAsync('new-customers', { payload })
  }

  const topAffiliatesAsync = async (payload) => {
    return await api.postAsync('top-affiliates', { payload })
  }

  const topProductsAsync = async (payload) => {
    return await api.postAsync('top-products', { payload })
  }

  const topCategoriesAsync = async (payload) => {
    return await api.postAsync('top-categories', { payload })
  }

  return { orderStatisticsAsync, salesRevenueAsync, newCustomersAsync, topCategoriesAsync, topProductsAsync, topAffiliatesAsync }
}

export default useDashboardService
