import useApiConnection from '../apiConnection'
import { PRODVENTORY_ENDPOINTS } from '../apiEndpoints'

const useWarehouseService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_PROVENTOY
  const api = useApiConnection(endpoint, PRODVENTORY_ENDPOINTS.WAREHOUSE)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const geBySupplierIdAsync = async (supplierId) => {
    return await api.getAsync('get-by-supplierId', { payload: { supplierId } })
  }

  const getComboboxAsync = async () => {
    return await api.getAsync('combobox')
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getByIds = async (payload) => {
    return await api.postAsync('get-by-ids', { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  return { getByIdAsync, geBySupplierIdAsync, getComboboxAsync, getListAsync, getByIds, insertAsync, updateAsync, deleteAsync }
}

export default useWarehouseService
