import React from 'react'
import { Pie } from '@ant-design/charts'

const PieChart = ({ data }) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0)

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'label',
    innerRadius: 0.6,
    interactions: [{ type: 'element-active' }],
  }

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <Pie {...config} />
      <div
        style={{
          position: 'absolute',
          top: '54%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '30px',
          fontWeight: 'bold',
        }}
      >
        {totalValue}
      </div>
    </div>
  )
}

export default PieChart
