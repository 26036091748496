import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useOrderService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_ECOM
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.ORDER)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getListExportAsync = async (payload) => {
    return await api.postAsync('export-excel', { payload })
  }

  const preparingGoodsAsync = async (id) => {
    return await api.postAsync(`preparing-goods/${id}`)
  }

  const waitForDeliveryAsync = async (id) => {
    return await api.postAsync(`wait-for-delivery/${id}`)
  }

  const deliveryReceivedAsync = async (id) => {
    return await api.postAsync(`delivery-received/${id}`)
  }

  const completeAsync = async (id) => {
    return await api.postAsync(`complete/${id}`)
  }

  const cancelAsync = async (id) => {
    return await api.postAsync(`cancel/${id}`)
  }

  const returnRefundAsync = async (id) => {
    return await api.postAsync(`return-refund/${id}`)
  }

  const countOrderAsync = async () => {
    return await api.getAsync(`count-order`)
  }

  return {
    getByIdAsync,
    getListAsync,
    preparingGoodsAsync,
    waitForDeliveryAsync,
    deliveryReceivedAsync,
    completeAsync,
    cancelAsync,
    returnRefundAsync,
    getListExportAsync,
    countOrderAsync
  }
}

export default useOrderService
