import { Route, Routes, Outlet } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../components/layout/header/breadcrumb/PageData'
import FeaturePermissions from '../pages/settings/featurePermission/FeaturePermissions'
import UserManagement from '../pages/settings/userManagement/UserManagement'
import AccountHeader from '../pages/settings/user/AccountHeader'
import ChangePassword from '../pages/settings/user/ChangePassword'
import Overview from '../pages/settings/user/Overview'
import Settings from '../pages/settings/user/Settings'
import Billing from '../pages/settings/user/Billing'
import { AccountHeaderProvider } from '../pages/settings/user/components/AccountHeaderContext'
import { useAuth } from '../components/auth/AuthContext'
import { getMenuItems } from '../utilities/sharedFunction'

export const settingMenuItems = [
  {
    section: 'Menu.Settings',
    items: [
      {
        to: 'user-management/list',
        icon: 'TeamOutlined',
        title: 'Settings.UserManagement',
        id: 'settings-user-management-list',
        component: UserManagement,
      },
      // {
      //   to: 'feature-permissions',
      //   icon: 'AppstoreOutlined',
      //   title: 'Settings.FeaturePermissions',
      //   id: 'settings-feature-permissions',
      //   component: FeaturePermissions,
      // },
    ],
  },
  {
    section: 'User.Account',
    items: [
      {
        id: 'user-overview',
        component: Overview,
        to: 'user-profile/overview',
        path: 'overview',
        title: 'User.UserProfile',
        icon: 'UserOutlined',
      },
      {
        id: 'user-settings',
        component: Settings,
        to: 'user-profile/settings',
        path: 'settings',
        title: 'User.UserSetting',
        icon: 'SettingOutlined',
      },
      {
        id: 'user-billing',
        component: Billing,
        to: 'user-profile/billing',
        path: 'billing',
        title: 'User.Billing',
        icon: 'WalletOutlined',
      },
      {
        id: 'user-changePassword',
        component: ChangePassword,
        to: 'user-profile/change-password',
        path: 'change-password',
        title: 'User.ChangePassword',
        icon: 'KeyOutlined',
      },
    ],
  },
]

const SettingRoutes = () => {
  const intl = useIntl()
  const { userPermissions } = useAuth()

  const renderRoutes = (items) => {
    return items.flatMap((item) => {
      if (item.items) {
        if (item.section === 'User.Account') {
          return renderUserSettingRoutes(item.items)
        } else {
          return renderRoutes(item.items)
        }
      }

      if (item.component) {
        const breadCrumbs = [{ title: intl.formatMessage({ id: 'Menu.Settings' }) }]
        return (
          <Route
            key={item.id}
            path={item.to}
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: item.title })}</PageTitle>
                <item.component />
              </>
            }
          />
        )
      }

      return []
    })
  }

  const renderUserSettingRoutes = (items) => {
    return (
      <Route
        key='user-profile'
        path='user-profile/*'
        element={
          <AccountHeaderProvider>
            <AccountHeader userSettings={items} />
            <Outlet />
          </AccountHeaderProvider>
        }
      >
        {items.map((item) => {
          const breadCrumbs = [{ title: intl.formatMessage({ id: 'User.Account' }) }]
          return (
            <Route
              key={item.id}
              path={item.path}
              element={
                <>
                  <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: item.title })}</PageTitle>
                  <item.component />
                </>
              }
            />
          )
        })}
      </Route>
    )
  }

  return <Routes>{renderRoutes(getMenuItems(settingMenuItems, userPermissions))}</Routes>
}
export default SettingRoutes
