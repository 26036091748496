/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '../shared/Icon'
import { useAuthService } from '../../services/apiServices'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../shared/FormControl'
import { useAuth } from './AuthContext'

const Login = ({ returnUrl, emailConfirmation }) => {
  const intl = useIntl()
  const { loginAsync } = useAuthService()
  const { reloadUser } = useAuth()
  const componentRefs = useRef({})
  let tabIndex = 1

  const [userName, setUserName] = useState(null)
  const [password, setPassword] = useState(null)

  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setIsSubmitting(true)
      const isValid = await validationAllComponents(componentRefs)
      if (isValid) {
        const { error, result } = await loginAsync({ userName, password })
        if (error) {
          setErrors([error.message])
        } else {
          reloadUser(result)
        }
      }
    } finally {
      setIsSubmitting(false)
    }

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
          <FormattedMessage id='Login' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a href='#' className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>
            <img alt='Logo' src='/media/svg/brand-logos/google-icon.svg' className='h-15px me-3' />
            <FormattedMessage id='LoginWithGoogle' />
          </a>
        </div>
        <div className='col-md-6'>
          <a href='#' className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>
            <img alt='Logo' src='/media/svg/brand-logos/apple-black.svg' className='theme-light-show h-15px me-3' />
            <img alt='Logo' src='/media/svg/brand-logos/apple-black-dark.svg' className='theme-dark-show h-15px me-3' />
            <FormattedMessage id='LoginWithApple' />
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-150px text-gray-500 fw-semibold fs-7'>
          <FormattedMessage id='Login.OrWithEmail' />
        </span>
      </div>

      {Object.keys(errors).length > 0 && (
        <div className='text-danger'>
          {Object.values(errors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}

      {emailConfirmation && (
        <div className='d-flex justify-content-center text-align-center mb-4'>
          <span className='menu-icon me-1'>
            <i className='ki-duotone ki-sms fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>
          <a href='#' className='link-primary cursor-pointer'>
            <span className='indicator-label'>
              <FormattedMessage id='ResendConfirmationEmail' />
            </span>
            <span className='indicator-progress'>
              <FormattedMessage id='PleaseWait' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </a>
        </div>
      )}

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.userName = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'UserName' })}
          value={userName}
          onChange={setUserName}
          tabIndex={tabIndex++}
          isRequired={true}
        />
      </div>

      <div className='fv-row mb-3'>
        <FormControl
          ref={(ref) => (componentRefs.current.password = ref)}
          tabIndex={tabIndex++}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Password' })}
          value={password}
          isRequired={true}
          isPassword={true}
          hideText={true}
          onChange={setPassword}
        />
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8 justify-content-end'>
        <a href='#' className='link-primary'>
          <FormattedMessage id='Login.ForgotPassword' />
        </a>
      </div>

      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={isSubmitting}>
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <FormattedMessage id={isSubmitting ? 'PleaseWait' : 'Login'} />
            <Icon iconName={isSubmitting ? 'LoadingOutlined' : ''} />
          </div>
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <FormattedMessage id='Login.NotAMemberYet' />{' '}
        <a href='#' className='link-primary'>
          <FormattedMessage id='Register' />
        </a>
      </div>
    </form>
  )
}

export default Login
