import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import { useUserService } from '../../../services/apiServices'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import Icon from '../../../components/shared/Icon'
import { Tag, Tooltip } from 'antd'
import { ACTIVE_ENUM, getEnums } from '../../../utilities/enum'
import ApproveConfirm from '../../../components/shared/ApproveConfirm'

const UserManagement = () => {
  const intl = useIntl()
  const { getListAsync, resetPasswordAsync, changeStatusAsync } = useUserService()
  const [filter, setFilter] = useState({})
  const [onReload, setOnReload] = useState(false)

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const handleResetPassword = async (email) => {
    const { error } = await resetPasswordAsync(email)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleChangeStatus = async (id, status) => {
    const { error } = await changeStatusAsync(id, status)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.User.Avatar' })}`,
      dataIndex: 'avatarUrl',
      width: 60,
      render: (avatarUrl, record) => (
        <div className='symbol symbol-35px symbol-circle d-flex justify-content-center'>
          {avatarUrl ? (
            <img src={avatarUrl} alt={record.fullName} />
          ) : (
            <span className='symbol-label bg-light-danger text-danger fw-semibold'>{record.fullName.charAt(0)}</span>
          )}
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.User.FullName' })}`,
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.User.UserName' })}`,
      dataIndex: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.User.Email' })}`,
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.User.PhoneNumber' })}`,
      dataIndex: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      width: 80,
      showSorterTooltip: false,
      render: (phoneNumber) => (phoneNumber ? `+84 ${phoneNumber}` : ''),
    },
    {
      title: `${intl.formatMessage({ id: 'IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Message' })}
            onConfirm={() => handleResetPassword(item.email)}
            tooltip={intl.formatMessage({ id: 'Ecom.User.ResetPassword' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-success'>
                <Icon iconName='ReloadOutlined' />
              </div>
            }
          />

          {item.isActive ? (
            <Confirm
              title={intl.formatMessage({ id: 'Notifications' })}
              description={intl.formatMessage({ id: 'Confirm.Message' })}
              onConfirm={() => handleChangeStatus(item.id, false)}
              tooltip={intl.formatMessage({ id: 'Ecom.User.Disable' })}
              button={
                <div className='btn btn-sm btn-icon btn-light-danger'>
                  <Icon iconName='StopOutlined' />
                </div>
              }
            />
          ) : (
            <Confirm
              title={intl.formatMessage({ id: 'Notifications' })}
              description={intl.formatMessage({ id: 'Confirm.Message' })}
              onConfirm={() => handleChangeStatus(item.id, true)}
              tooltip={intl.formatMessage({ id: 'Ecom.User.IsActive' })}
              button={
                <div className='btn btn-sm btn-icon btn-light-primary'>
                  <Icon iconName='CheckOutlined' />
                </div>
              }
            />
          )}
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-end gap-4'>
      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
    </div>
  )
}

export default UserManagement
