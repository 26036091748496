import { v4 as uuidv4 } from 'uuid'

export const MODULE = {
  PRODVENTORY: 'prodventory',
  ECOM: 'ecom',
  SETTINGS: 'settings',
}

export const DATETIME_FORMAT_SLASH = {
  ddMMyyyy: 'DD/MM/YYYY',
  ddMMyyyyHHmm: 'DD/MM/YYYY HH:mm',
}

export const DATETIME_FORMAT_DASH = {
  yyyyMMdd: 'YYYY-MM-DD',
}

export const ROLE = {
  ADMIN: 'Admin',
  SUPPLIER: 'Supplier',
  AFFILIATE: 'Affiliate',
  CUSTOMER: 'Customer',
}

export const GUID_NEWID = () => uuidv4()

export const DEFAULT_VALUE = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  REGEX_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
  GUID_RANDOM: uuidv4(),
  REGEX_PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$_%\-\^&*(),.?":{}|<>])[A-Za-z\d!@#$_%\-\^&*(),.?":{}|<>]{8,}$/,
  REGEX_PHONENUMBER: /^\+?\d{10,11}$/,
  VND: '₫',
  PERCENT: '%',
  SLASH: '/',
}

export const BASE_URL_API_SLEEK_ECOM = process.env.REACT_APP_API_SLEEK_ECOM.replace('/api', '')
export const BASE_URL_API_SLEEK_PROVENTOY = process.env.REACT_APP_API_SLEEK_PROVENTOY.replace('/api', '')
