import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import RangePicker from '../../../components/shared/RangePicker'
import OrderStatistics from './components/OrderStatistics'
import SalesRevenue from './components/SalesRevenue'
import TopAffiliates from './components/TopAffiliates'
import TopCategories from './components/TopCategories'
import TopProducts from './components/TopProducts'
import NewCustomers from './components/NewCustomer'
import { useSupplierService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { isEmpty } from 'lodash'

const Dashboard = () => {
  const intl = useIntl()
  const { user } = useAuth()

  const componentRefs = useRef({})
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()
  const [dates, setDates] = useState([dayjs(), dayjs()])
  const [supplierId, setSupplierId] = useState(user?.profile?.supplierId?.trim() || null)

  let tabIndex = 1

  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    fetchSuppliers()
  }, [])

  return (
    <div id='dashboard' className='d-flex flex-column gap-4'>
      <div id='global_filter' className='d-flex gap-4'>
        <div className='col-md-3'>
          <FormControl
            controlType={CONTROL_TYPE.SELECT}
            ref={(ref) => (componentRefs.current.supplierId = ref)}
            label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
            value={supplierId}
            onChange={setSupplierId}
            tabIndex={tabIndex++}
            options={suppliers}
            isHideLabel={true}
          />
        </div>
        <RangePicker dates={dates} onChange={setDates} allowClear={false} />
      </div>
      <div className='row'>
        <div className='col-xxl-4 mb-4 mb-xxl-0'>
          <OrderStatistics dates={dates} supplierId={supplierId} />
        </div>
        <div className='col-xxl-8'>
          <SalesRevenue year={new Date().getFullYear()} supplierId={supplierId} />
        </div>
      </div>

      <div className='row'>
        <div className='col-xxl-12'>
          <NewCustomers dates={dates} supplierId={supplierId} />
        </div>
      </div>

      <div className='row'>
        <div className='col-xxl-8 mb-4 mb-xxl-0'>
          <div className='col-12 mb-4'>
            <TopAffiliates dates={dates} />
          </div>
          <div className='col-12'>
            <TopProducts dates={dates} supplierId={supplierId} />
          </div>
        </div>
        <div className='col-xxl-4'>
          <TopCategories dates={dates} supplierId={supplierId} />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
