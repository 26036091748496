import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { MenuComponent } from '../../assets/ts/components/index.ts'
import { PageDataProvider } from '../layout/header/breadcrumb/PageData'
import ScrollTop from './ScrollTop'
import Header from './header/Header.js'
import Aside from './aside/Aside.js'
import { SidebarProvider } from './aside/SidebarContext.js'

const MasterLayout = () => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  return (
    <PageDataProvider>
      <SidebarProvider>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <Aside />
            <div id='wrapper' className='wrapper d-flex flex-column flex-row-fluid'>
              <Header />
              <div id='content' className='content d-flex flex-column flex-column-fluid'>
                <div id='content_container' className='container-xxl'>
                  <Outlet />
                </div>
              </div>
              <div id='footer' className='footer py-4 d-flex flex-lg-column'>
                <div className='container-xxl d-flex flex-column flex-md-row flex-stack'>
                  <div className='text-dark order-2 order-md-1'>
                    <span className='text-gray-400 fw-semibold me-1'>Created by</span>
                    <a href='/' target='_blank' className='text-muted text-hover-primary fw-semibold me-2 fs-6'>
                    Green24h &#169; 2024
                    </a>
                  </div>
                  <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
                    <li className='menu-item'>
                      <a href='/' target='_blank' className='menu-link px-2'>
                        About
                      </a>
                    </li>
                    <li className='menu-item'>
                      <a href='/' target='_blank' className='menu-link px-2'>
                        Support
                      </a>
                    </li>
                    <li className='menu-item'>
                      <a href='/' target='_blank' className='menu-link px-2'>
                        Purchase
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </SidebarProvider>
    </PageDataProvider>
  )
}

export default MasterLayout
