import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import { usePromotionService } from '../../../services/apiServices'
import PromotionEditor from './PromotionEditor'
import Icon from '../../../components/shared/Icon'
import TableFilter from '../../../components/shared/TableFilter'
import PromotionFilter from './PromotionFilter'
import { Tooltip } from 'antd'
import { PROMOTION_DISCOUNT_TYPE_ENUM } from '../../../utilities/enum'
import NumberFormat from '../../../components/shared/NumberFormat'
import { downloadFile } from '../../../utilities/sharedFunction'
import { BASE_URL_API_SLEEK_ECOM } from '../../../utilities/constant'

const Promotions = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync, getListExportAsync } = usePromotionService()
  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const handleExportExcel = async () => {
    setOnReload(false)
    const { result } = await getListExportAsync({ ...filter })
    if (result && result !== '') {
      downloadFile(BASE_URL_API_SLEEK_ECOM + result)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.Banner' })}`,
      dataIndex: 'fileBanner',
      width: 80,
      render: (fileBanner) => (
        <div className='symbol d-flex justify-content-center'>
          <img className={`symbol-label`} src={fileBanner.url} alt={fileBanner.name} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.Name' })}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.FromDate' })}`,
      dataIndex: 'fromDateStr',
      sorter: (a, b) => a.fromDateStr.localeCompare(b.fromDateStr),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.ToDate' })}`,
      dataIndex: 'toDateStr',
      sorter: (a, b) => a.toDateStr?.localeCompare(b.toDateStr),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.DiscountType' })}`,
      key: 'discountType',
      sorter: (a, b) => a.discountType - b.discountType,
      width: 80,
      showSorterTooltip: false,
      render: (item) =>
        item.discountType === PROMOTION_DISCOUNT_TYPE_ENUM.FIXED_PRICE ? (
          <NumberFormat amount={item.discountValue} suffix='đ' />
        ) : item.discountType === PROMOTION_DISCOUNT_TYPE_ENUM.PERCENTAGE ? (
          <NumberFormat amount={item.discountValue} suffix='%' />
        ) : (
          intl.formatMessage({ id: `Enum.${item.discountTypeName.toUpperCase()}` }, { 0: item.discountValue })
        ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.Type' })}`,
      dataIndex: 'typeName',
      sorter: (a, b) => a.typeName.localeCompare(b.typeName),
      width: 80,
      showSorterTooltip: false,
      render: (typeName) => `${intl.formatMessage({ id: `Enum.${typeName.toUpperCase()}` })}`,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex justify-content-between flex-column flex-md-row gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success me-5'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />

        <Button
          title={intl.formatMessage({ id: 'ExportExcel' })}
          icon='ExportOutlined'
          className='btn-primary'
          onClick={() => handleExportExcel()}
          tabIndex={1}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<PromotionFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
      <PromotionEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Promotions
