import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useAuthService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.AUTH)

  const loginAsync = async (payload) => {
    return await api.postAsync('login', { payload })
  }

  const logoutAsync = async () => {
    return await api.postAsync('')
  }

  return { loginAsync, logoutAsync }
}

export default useAuthService
