export const GLOBALADMIN_ENDPOINTS = {
  USER: 'users',
  LOCALIZATION: 'localizations',
  LOCATION: 'locations',
  BANK_ACCOUNT: 'bank-accounts',
  AUTH: 'auth',
}

export const ECOM_ENDPOINTS = {
  BANNER: 'banners',
  AFFILIATE: 'affiliates',
  CUSTOMER: 'customers',
  FEATURED_PRODUCTS: 'featured-products',
  LEVEL: 'levels',
  LEVEL_GROUP: 'levels/groups',
  NEWS: 'news',
  ORDER: 'orders',
  PROMOTION: 'promotions',
  SUPPLIER: 'suppliers',
  VOUCHER: 'vouchers',
  SUPPLIER_MEMBERSHIP: 'supplier-memberships',
  WITHDRAWAL_HISTORIES: 'withdrawal-histories',
  DASHBOARD: 'dashboards',
  SALES_REVENUES: 'sales-revenues',
}

export const PRODVENTORY_ENDPOINTS = {
  CATEGORY: 'categories',
  SUB_CATEGORY: 'categories/subs',
  CATEGORY_TAG: 'categories/tags',
  WAREHOUSE: 'warehouses',
  PRODUCT: 'products',
  PRODUCT_INVENTORY: 'products/inventories',
  INVENTORY_IN: 'warehouses/inventories-in',
  INVENTORY_OUT: 'warehouses/inventories-out',
}
