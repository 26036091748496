import useApiConnection from '../apiConnection'
import { PRODVENTORY_ENDPOINTS } from '../apiEndpoints'

const useInventoryOutService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_PROVENTOY
  const api = useApiConnection(endpoint, PRODVENTORY_ENDPOINTS.INVENTORY_OUT)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  return { getByIdAsync, getListAsync }
}

export default useInventoryOutService
