import useApiConnection from '../apiConnection'
import { PRODVENTORY_ENDPOINTS } from '../apiEndpoints'

const useCategoryService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_PROVENTOY
  const api = useApiConnection(endpoint, PRODVENTORY_ENDPOINTS.CATEGORY)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getCurrentListAsync = async () => {
    return await api.getAsync('current-list')
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }
  return { getByIdAsync, getListAsync, getCurrentListAsync, insertAsync, updateAsync, deleteAsync }
}

export default useCategoryService
