import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useSupplierService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_ECOM
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.SUPPLIER)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getListExportAsync = async (payload) => {
    return await api.postAsync('export-excel', { payload })
  }

  const getComboboxAsync = async () => {
    return await api.getAsync('combobox')
  }

  const getComboboxByWarehouseIdAsync = async (warehouseId) => {
    return await api.getAsync('get-combobox-by-warehouseId', { payload: { warehouseId } })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const approveAsync = async (payload) => {
    return await api.postAsync(`approve`, { payload })
  }

  const rejectAsync = async (payload) => {
    return await api.postAsync(`reject`, { payload })
  }

  const getAccountBalanceAsync = async () => {
    return await api.getAsync(`get-account-balance`)
  }

  return {
    getByIdAsync,
    getListAsync,
    getListExportAsync,
    getComboboxAsync,
    getComboboxByWarehouseIdAsync,
    insertAsync,
    updateAsync,
    deleteAsync,
    approveAsync,
    rejectAsync,
    getAccountBalanceAsync
  }
}

export default useSupplierService
